import React from "react"
import theme from "theme"
import { Theme, Text, Section } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"services"} />
      <Helmet>
        <title>Our services | In-blase Food Delivery</title>
        <meta
          name={"description"}
          content={
            "In-Blase Food Delivery is dedicated to bringing a different and delicious food experience directly to your home.Our range of services makes it possible to have something that will satisfy you with your deserves, regardless of your longing."
          }
        />
        <meta
          property={"og:title"}
          content={"Our services | In-blase Food Delivery"}
        />
        <meta
          property={"og:description"}
          content={
            "In-Blase Food Delivery is dedicated to bringing a different and delicious food experience directly to your home.Our range of services makes it possible to have something that will satisfy you with your deserves, regardless of your longing."
          }
        />
        <meta
          property={"og:image"}
          content={"https://in-blasetump.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://in-blasetump.com/img/6425534.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://in-blasetump.com/img/6425534.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://in-blasetump.com/img/6425534.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://in-blasetump.com/img/6425534.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://in-blasetump.com/img/6425534.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://in-blasetump.com/img/6425534.png"}
        />
        <meta
          name={"msapplication-TileColor"}
          content={"https://in-blasetump.com/img/6425534.png"}
        />
      </Helmet>
      <Components.Header />
      <Section padding="80px 0" sm-padding="40px 0 20px">
        <Override slot="SectionContent" align-items="center" />
        <Text
          as="h1"
          font="--headline1"
          md-font="--headline1"
          margin="20px 0 0 0"
        >
          Our services
        </Text>
        <Text as="p" font="--lead" margin="20px 0 0px 0" text-align="center">
          In-Blase Food Delivery is dedicated to bringing a different and
          delicious food experience directly to your home.Our range of services
          makes it possible to have something that will satisfy you with your
          deserves, regardless of your longing.
        </Text>
      </Section>
      <Components.Services />
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
